import style from './BankingLicense.module.scss';
import Services from '../../../components/Services/Services';
import banking1 from '../../../assets/images/banking1.jpg'

const BankingLicense = () => {
  return (
    <div className={style.content}>
      <div className={style.start_block}>
        <h1 className={style.start_block__header}>
          Your Gateway To An Anjouan Comoros Offshore License
        </h1>
        <div className={style.start_block__text}>
          Banking License
        </div>
      </div>
      <div className={style.info_block}>
        <div className={style.info_block__text}>
          <p className={style.base}>
            Application for permission for a banking license should be made to the Anjouan Offshore Finance 
            Authority after an IBG is obtained after completing an IFPBG application forms from the Registrar 
            of International Businesses. On top of the KYG and due diligence requirement, the following 
            documents are required:<br/><br/>
          </p>
          <ol className={style.small}>
            <li>
              A detailed business plan of the company: Activities of the bank; Gountries in which the bank is 
              looking to invest; The percentage of ownership by the shareholders; The details of the ultimate 
              beneficial owners (chart may help if many); Any other useful information to support the application.
            </li>
            <li>CVs of all the directors</li>
            <li>Full contact details of the directors and shareholders (KYG questionnaire)</li>
            <li>Bank reference for all the beneficial owners</li>
            <li>Clear certified passport copies of all beneficial owners</li>
            <li>Proof of addresses (utility bills)</li>
            <li>Business or legal reference for the beneficial owners</li>
            <li>Legal documents for the shareholding companies (articles, certificate of incorporation etc)</li>
            <li>Latest audited financial statements for all shareholding companies (if any)</li>
            <li>A certificate to prove that the beneficial owners do not have a criminal record</li>
            <li>
              Furthermore, all companies with banking licenses are required to submit their annual accounts 
              to the Authority.
            </li>
          </ol><br/><br/>

          <h3>KYG AND DUE DILIGENCE</h3><br/><br/>
          <p className={style.small}>
            A charge is made for each director / shareholder for due diligence (cost is dependent on country of residence), 
            in the event that a client is not accepted for a license this payment is not refundable.
          </p>
          <br/>
          <p className={style.small}>
            The following documents for each of the beneficial owner, director, manager, authorized signatory, 
            shareholder/member of the Entity or agent under power of attorney must be submitted:
          </p><br/><br/>

          <p className={style.base}>(a) For Individual</p><br/><br/>
          <p className={style.small}>
              A certified copy of passport or current driving license or national identity card showing the 
              name, date and place of birth, nationality, signature of holder and date of issue/expiry. 
              The document must be certified by a banker, lawyer or actuary or notary or accountant holding 
              a recognized professional qualification.
            
              Proof of residential address. Acceptable evidence includes an original utility bill, bank or 
              credit card statement.
              
              Original Bank Reference.
          </p><br/><br/>

          <p className={style.base}>(b) For Corporate/Partnership</p><br/><br/>
          <p className={style.small}>
              Certificate of incorporation or registration and the memorandum and articles of association or 
              constitution or deed of partnership or Act de Société as the case may be. Document has to be 
              certified by banker, lawyer or actuary or notary or accountant holding a recognized professional 
              qualification. Original Bank Reference.
          </p><br/><br/>
          <ul className={`${style.small} ${style.bottom_list}`}>
            <li>A certified true copy of latest audited account or annual report.</li>
            <li>A Certificate of good standing (for Société).</li>
            <li>
              List of substantial shareholders (who holds not less than 5% of the voting power exercisable at 
              the meeting of shareholder/member) and a list of directors, and corporate structure.
            </li>
            <li>
              A certified copy of Board resolution granting authority to its managers, officers or employees 
              to transact business on its behalf.
            </li>
          </ul>
        </div>
        <img src={banking1} alt="" />
      </div>
      <Services />
    </div>
  );
}

export default BankingLicense;