import React, { useEffect } from 'react';
import './App.css';
import { Route, Routes, useLocation } from "react-router-dom"
import { mainRoutes, licensesRoutes } from "./Constants/Routes"
import Header from '../src/components/Header/Header'  
import MobileHeader from '../src/components/MobileHeader/MobileHeader'
import Footer from './components/Footer/Footer'
import ContactForm from './components/ContactForm/ContactForm'
import './styles/_main.scss'

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="App">
      <Header />
      <MobileHeader/>
      <Routes>
        {
            mainRoutes.map(route => {
                return (
                    <Route path={route.path} element={route.element} key={route.path} />
                )
            })
        }

        {
            licensesRoutes.map(route => {
                return (
                    <Route path={route.path} element={route.element} key={route.path} />
                )
            })
        }
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
