export const HOME = "/";
export const ABOUT = "/about";
export const SERVICES = "/services";
export const LICENSES = "/licenses";
export const BANKING_LICENSE = "/licenses/banking-license";
export const BROKERAGE_LICENSE = "/licenses/brokerage-license";
export const INSURANCE_LICENSE = "/licenses/insurance-license";
export const GAMING_LICENSE = "/licenses/gaming-license"
export const CONTACT = "/contact";

