import style from './ServicesPage.module.scss';
import Services from '../../components/Services/Services';
import Principles from '../../components/Principles/Principles';
import services from '../../assets/images/services3.jpg'

const ServicesPage = () => {
  return (
    <div className={style.content}>
      <div className={style.start_block}>
        <h1 className={style.start_block__header}>
          Your Gateway To An Anjouan Comoros Offshore License
        </h1>
        <div className={style.start_block__text}>
          We would like to introduce <br/>
          you new world space for doing <br/>
          offshore business
        </div>
      </div>
      <div className={style.info_block}>
        <div className={style.info_block__text}>
          We are dedicated to helping our clients with a wide range of financial and business services, 
          including the formation of International Business Companies (IBCs), the establishment of banks, 
          obtaining licenses for any type of financial institution, and the buying and selling of banks. 
          Additionally, our services include obtaining gaming licenses within the jurisdiction of Anjouan, 
          Comoros.    
          <br/><br/>
          Moreover, we offer comprehensive assistance with establishing International Bank (IB) accounts. 
          Our expertise extends to providing a variety of core banking software solutions and IT services 
          designed to help you efficiently and securely manage your financial institution. These services 
          encompass everything from initial setup and integration to ongoing support and maintenance, 
          ensuring that your operations run smoothly and effectively.    
          <br/><br/>
          Our team is equipped to guide you through the complexities of regulatory compliance, risk 
          management, and operational efficiency. We also offer consultancy on best practices for 
          leveraging technology to enhance your financial services, including advanced cybersecurity 
          measures, data management systems, and customer relationship management tools.    
          <br/><br/>
          By partnering with us, you gain access to a wealth of knowledge and resources that can help you 
          navigate the challenges of the financial industry. Whether you are looking to start a new venture, 
          expand your existing operations, or optimize your current processes, we are here to provide the 
          support and expertise you need to achieve your goals.    
          <br/><br/>
          Our commitment to excellence and client satisfaction means that we tailor our services to meet 
          your specific needs, ensuring that you receive personalized and effective solutions. Trust us to 
          be your reliable partner in the ever evolving world of finance and business.
        </div>
        <img src={services} alt="" />
      </div>
      <Services />
      <Principles />
    </div>
  );
}

export default ServicesPage;