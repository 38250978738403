import style from './Contact.module.scss';
import ContactForm from "../../components/ContactForm/ContactForm";

const Contact = () => {
  return (
      <>
          <div className={style.content}>
              <div className={style.start_block}>
                  <h1 className={style.start_block__header}>
                      Contact Us
                  </h1>
              </div>
              <h3 className={style.info}>
                  Get in touch with us by contacting
                  <br/><br/>
                  management@anjouanregister.com
                  <br/><br/>
                  +2694582620
                  <br/><br/>
                  or Simply fill the form below
              </h3>
          </div>
          
          <ContactForm />
      </>
  );
}

export default Contact;