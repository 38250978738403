import style from './Header.module.scss';
import { Link, useLocation } from 'react-router-dom';
import { 
  HOME,
  ABOUT,
  SERVICES,
  CONTACT,
  BANKING_LICENSE,
  BROKERAGE_LICENSE,
  INSURANCE_LICENSE,
  GAMING_LICENSE
 } from '../../Constants/RoutesConstants'
 
import logo from '../../assets/images/logo.png'
import phone from '../../assets/images/phone.svg'
import mail from '../../assets/images/envelope.svg'

const Header = () => {

  const location = useLocation()

  return (
    <header className={style.content}>
      <div className={style.info}>
        <Link to={HOME} className={style.logo}>
          <img src={logo} alt="logo" />
        </Link>
        <div className={style.contacts}>
          <div className={`${style.phone} ${style.contacts__icon}`}>
            <img src={phone} alt="phone" 
                 className={style.phone__icon}
            />
            <div className={style.phone__text}>
              <a href="tel:+2694582620">
                +2694582620
              </a>
            </div>
          </div>
          <div className={` ${style.mail} ${style.contacts__icon}`}>
            <img src={mail} alt="mail" 
                 className={style.mail__icon}
            />
            <div className={style.mail__text}>
              <a href="mailto:management@anjouanregister.com">
                management@anjouanregister.com
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={style.nav}>
        <ul className={style.nav__links}>
          <li className={location.pathname === '/' ? style.nav__link_active : style.nav__link}>
            <Link to={HOME}>
              Home
            </Link>
          </li>
          <li className={location.pathname.includes("about") ? style.nav__link_active : style.nav__link}>
            <Link to={ABOUT}>
              About
            </Link>
          </li>
          <li className={location.pathname.includes("services") ? style.nav__link_active : style.nav__link}>
            <Link to={SERVICES}>
              Services
            </Link>
          </li>
          <li className={`${location.pathname.includes("licenses") ? style.nav__link_active : style.nav__link} 
          ${style.nav__sub}`}>
            Licenses
            <ul className={style.nav__sub_menu}>
              <li>
                <Link to={BANKING_LICENSE}>
                  Banking License
                </Link>
              </li>
              <li>
                <Link to={BROKERAGE_LICENSE}>
                  Brokerage License
                </Link>
              </li>
              <li>
                <Link to={GAMING_LICENSE}>
                  Gaming License
                </Link>
              </li>
              <li>
                <Link to={INSURANCE_LICENSE}>
                  Insurance License
                </Link>
              </li>
            </ul>
          </li>
          <li className={location.pathname.includes("contact") ? style.nav__link_active : style.nav__link}>
            <Link to={CONTACT}>
              Contact
            </Link>
          </li>
        </ul>
      </div>
    </header>
  );
}

export default Header;