import style from './Principles.module.scss';
import principles1 from '../../assets/images/principles-01.svg'
import principles2 from '../../assets/images/principles-02.svg'
import principles3 from '../../assets/images/principles-03.svg'
import principles4 from '../../assets/images/principles-04.svg'
import principles5 from '../../assets/images/principles-05.svg'

const Principles = () => {
  const principles = [
    {
      title: 'Confidentiality',
      icon: principles1,
    },
    {
      title: 'Data protection',
      icon: principles2,
    },
    {
      title: 'Legality',
      icon: principles3,
    },
    {
      title: 'Individual approach to the client',
      icon: principles4,
    },
    {
      title: 'Completion of tasks within the agreed time frame',
      icon: principles5,
    },
  ]

  return (
    <div className={style.content}>
      <h2 className={style.title}>
        We work adhering to the principles:
      </h2>
      <div className={style.principles}>
        {principles.map((item, e) => (
          <div className={style.principle__item} key={e}>
            <img src={item.icon} alt="" />
            <div className={style.principles__title}>
              {item.title}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Principles;