import style from './Services.module.scss';
import services1 from '../../assets/images/services-01.svg'
import services2 from '../../assets/images/services-02.svg'
import services3 from '../../assets/images/services-03.svg'
import services4 from '../../assets/images/services-04.svg'
import services5 from '../../assets/images/services-05.svg'
import services6 from '../../assets/images/services-06.svg'

const Services = () => {
  const main = [
  
    {
      title: 'Banking License',
      icon: services2,
    },
    {
      title: 'Brokerage License',
      subtitle: 'Forex licenses, cryptocurrencies, gambling, EMI, PSP, SVF, MSO, DLT',
      icon: services4,
    },
    {
      title: 'International Business Company',
      icon: services1,
    },
    {
      title: 'Licensing',
      subtitle: 'Forex, cryptocurrencies, gambling, EMI, PSP, SVF, MSO, DLT',
      icon: services3,
    },
   
    {
      title: 'Offshore company registration',
      subtitle: 'Expert advice on the acquisition of an offshore company',
      icon: services5,
    },
    {
      title: 'Gaming License',
      icon: services6,
    },
  ]

  const other = [
    'OTHER PRACTICES',
    'Accounting services',
    'Gambling',
    'Attorney at law',
    'Due diligence',
    'Corporate services',
    'Drafting contracts',
    'FinTech Services',
    'Forex trading license',
    'ICO consulting services',
    'Investment',
    'Trust registration',
    'Opening an account in the payment system',
    'The Preparation of Sertificates of GOOD…',
    'Registration of trademarks',
    'Tax consulting services',
    'Valuation and business-modeling',
  ]

  return (
    <div className={style.content}>
      <div className={style.title}>Discover our services</div>
      <div className={style.info}>
        <div className={style.main}>
          {
            main.map((item, e) => (
              <div className={style.main__item} key={e}>
                <img src={item.icon} alt="" />
                <div className={style.main__content}>
                  <p className={style.main__title}>
                    {item.title}
                  </p>
                  {item.subtitle && (
                    <p className={style.main__subtitle}>
                      {item.subtitle}
                    </p>
                  )}
                </div>
              </div>
            ))
          }
        </div>
        <div className={style.other}>
          {
            other.map((item, e) => (
              <div key={e} className={style.other__item}>
                {item}
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
}

export default Services;