import * as React from 'react';
import {
    HOME,
    ABOUT,
    SERVICES,
    CONTACT,
    BANKING_LICENSE,
    BROKERAGE_LICENSE,
    GAMING_LICENSE,
    INSURANCE_LICENSE
} from './RoutesConstants'
import Home from '../pages/Home/Home';
import About from '../pages/About/About';
import ServicesPage from '../pages/Services/ServicesPage';
import BankingLicense from '../pages/licenses/BankingLicense/BankingLicense';
import BrokerageLicense from '../pages/licenses/BrokerageLicense/BrokerageLicense';
import InsuranceLicense from '../pages/licenses/InsuranceLicense/InsuranceLicense';
import GamingLicense from '../pages/licenses/GamingLicense/GamingLicense'
import Contact from '../pages/Contact/Contact';

export const mainRoutes = [
    {
        name: 'Home',
        path: HOME,
        element: <Home />
    },
    {
        name: 'About',
        path: ABOUT,
        element: <About />
    },
    {
        name: 'Services',
        path: SERVICES,
        element: <ServicesPage />
    },
    {
        name: 'Contact',
        path: CONTACT,
        element: <Contact />
    },
];

export const licensesRoutes = [
    {
        name: 'Banking License',
        path: BANKING_LICENSE,
        element: <BankingLicense />
    },
    {
        name: 'Brokerage License',
        path: BROKERAGE_LICENSE,
        element: <BrokerageLicense />
    },
    {
        name: 'Insurance License',
        path: INSURANCE_LICENSE,
        element: <InsuranceLicense />
    },
    {
        name: 'Gaming License',
        path: GAMING_LICENSE,
        element: <GamingLicense />
    },
];