import style from './About.module.scss';
import Principles from '../../components/Principles/Principles';
import about2 from '../../assets/images/about2.jpg'
import about3 from '../../assets/images/about3.jpg'
import about4 from '../../assets/images/about4.jpg'

const About = () => {
  return (
    <div className={style.content}>
      <div className={style.start_block}>
        <h1 className={style.start_block__header}>
          Your Gateway To An Anjouan Comoros Offshore License
        </h1>
        <div className={style.start_block__text}>
          We would like to introduce<br/>
          you new world space for<br/>
          doing offshore business
        </div>
      </div>
      <div className={style.info1}>
        <div className={style.info1__title}>Anjoun Corporate Register</div>
        <div className={style.info1__info}>
          <div className={style.info1__text}>
            We are excited to introduce you to a new world space for conducting offshore business – Anjoun 
            is a self-governing dependency, Recognized as one of the world's leading rising offshore finance 
            centers, Anjoun has established itself as an ecommerce center of excellence. The Anjouan Offshore 
            finance authority is dedicated to the effective regulation and supervision of Anjoun's financial 
            services sector, adhering to internationally recognized standards of compliance. This positions 
            Anjoun as a preeminent financial center in the global financial services arena.  <br/>
            Establishing a business venture in the free zone of Anjoun is an attractive option for foreign 
            investors seeking to tap into the significant potential and excellent business environment that 
            Anjoun offers. There are no FATF (GAFI) or any other similar controls.  <br/>
            In the face of the global economic downturn, finding a jurisdiction where business can be 
            conducted without excessive taxation is increasingly difficult. Anjoun is a new player on the 
            world stage, ensuring economic growth by creating optimal conditions for offshore business. We 
            hope you will take advantage of our services and appreciate the benefits of registering your 
            business in Anjoun.  Our company closely collaborates with the Anjouan Offshore Finance Authority 
            as the sole certified agent. To access any services offered by Anjoun Offshore Finance Authority, 
            you need to contact us. Our active and professional team will process your application in the 
            hortest possible time and will promptly get in touch with you.
          </div>
          <div className={style.info1__images}>
            <img src={about2} alt="" />
            <img src={about3} alt="" />
          </div>
        </div>
      </div>
      <div className={style.info2}>
        <img src={about4} alt="" />
        <div className={style.info2__text}>
        To ensure Anjouan corporate register consistently conduct our global business in a professional, 
        ethical manner, we set out below our corporate mission statement, together with the values we 
        adopt in our daily business:  To get in and stay in the list of world’s preferred corporate 
        services provider, being the best at what we do, problem solving for our Clients; 
        <br/><br/>
        To be surrounded by fantastic Clients located in every country in the world;     
        <br/><br/>
        To be a firm that attracts talented employees wishing to work for and develop their careers;     
        <br/><br/>
        To conduct our business with openness, transparency, and honestly. To respect and uphold our Clients confidentiality needs;     
        <br/><br/>
        To continue to choose Clients who match our profile, ambitions, and values.
        </div>
      </div>
      <Principles />
    </div>
  );
}

export default About;