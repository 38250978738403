import React, { useState } from 'react';
import style from './ContactForm.module.scss';
import * as yup from 'yup';
import { Formik } from 'formik';
import emailjs from 'emailjs-com';

// Validation schema for the form
const validationSchema = yup.object({
  name: yup.string().required('Name is required'),
  email: yup.string().email('Invalid email format').required('Email is required'),
  phoneNumber: yup.string()
      .matches(/^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){0,20}(\s*)?$/, "Must be only digits")
      .min(6, "min 6")
      .max(20, "max 20")
      .required('Phone number is required'),
  message: yup.string().required('Message is required'),
});

const ContactForm = () => {
  const [responseMessage, setResponseMessage] = useState('');

  // Initial form values
  const initialValues = {
    name: '',
    email: '',
    phoneNumber: '',
    message: '',
  };

  // EmailJS configuration
  const serviceId = 'service_6hw8hgp';
  const templateId = 'template_30uewg9';
  const userId = 'IWqa-7oXbaIhc1Pk7';

  return (
      <div className={style.content}>
        <div className={style.content_body}>
          <div className={style.main_content}>
            <h1 className={style.title}>Contact us</h1>
            <div className={style.info_mobile}>
              Just fill out the form and we’ll get back to you within 24 hours to start the process of obtaining your offshore license.
            </div>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, { resetForm }) => {
                  emailjs.send(serviceId, templateId, values, userId)
                      .then((response) => {
                        console.log('SUCCESS!', response.status, response.text);
                        setResponseMessage('Form submitted successfully!');
                        resetForm();  // Reset the form after submission
                      })
                      .catch((error) => {
                        console.error('FAILED...', error);
                        setResponseMessage('An error occurred, please try again later.');
                      });
                }}
            >
              {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit
                }) => (
                  <form action="post" className={style.form} onSubmit={handleSubmit}>
                    <input type="text"
                           placeholder='Name'
                           value={values.name}
                           name='name'
                           onChange={handleChange}
                    />
                    {touched.name && errors.name ? (
                        <div className={style.error}>{errors.name}</div>
                    ) : null}
                    <input type="email"
                           placeholder='Email address'
                           value={values.email}
                           name='email'
                           onChange={handleChange}
                    />
                    {touched.email && errors.email ? (
                        <div className={style.error}>{errors.email}</div>
                    ) : null}
                    <input type="phone"
                           placeholder='Phone'
                           value={values.phoneNumber}
                           name='phoneNumber'
                           onChange={handleChange}
                    />
                    {touched.phoneNumber && errors.phoneNumber ? (
                        <div className={style.error}>{errors.phoneNumber}</div>
                    ) : null}
                    <textarea placeholder='Message'
                              value={values.message}
                              rows={4}
                              name='message'
                              onChange={handleChange} >
                </textarea>
                    {touched.message && errors.message ? (
                        <div className={style.error}>{errors.message}</div>
                    ) : null}
                    <button type='submit'>
                      Submit
                    </button>
                  </form>
              )}
            </Formik>

            {responseMessage &&
                <div className={style.response}>
                  {responseMessage}
                </div>
            }
          </div>
          <div className={style.info}>
            Just fill out the form and we’ll get back to you within 24 hours to start the process of obtaining your offshore license.
          </div>
        </div>
      </div>
  );
}

export default ContactForm;
