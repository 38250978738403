import style from './InsuranceLicense.module.scss';
import insurance1 from '../../../assets/images/insurance1.jpg'
import insurance2 from '../../../assets/images/insurance2.jpg'
import insurance3 from '../../../assets/images/insurance3.jpg'

const InsuranceLicense = () => {
  return (
    <div className={style.content}>
      <div className={style.start_block}>
        <h1 className={style.start_block__header}>
          Your Gateway To An Anjouan Comoros Offshore License
        </h1>
        <div className={style.start_block__text}>
          Gaming License
        </div>
      </div>
      <div className={style.info_block}>
        <div className={style.main_block}>
          <p>
            The International Insurance Act 2005 lays out the framework to facilitate the establishment of an 
            international insurance business from Anjouan. Application for insurance licences is made to the 
            Anjouan Commission for International Insurance by companies already holding an IBC ( International 
            Business Company). There is one class of insurance business authorised by the Anjouan Commission 
            which is a class 2 General Insurance License, insuring different parties? risks. In the case of 
            third party business the insurance company should demonstrate access to the necessary underwriting 
            and analytical skills, financial soundness and a good track record. Full details of all programmes 
            to be underwritten must be submitted for approval to the Anjoiuan Commission. The one class of 
            insurance licences that can be issued by the Anjouan Commission for International Insurance is 
            Class II General International Licence.
          </p>
          <img src={insurance1} alt="" />
        </div>
        <div className={style.formation_block}>
          <div className={style.formation_block__images}>
            <img src={insurance2} alt="" />
            <img src={insurance3} alt="" />
          </div>
          <div className={style.formation_block__info}>
            <p>
              Formation<br/>
              An application to form an international insurance company should be submitted to the Anjouan Commission for 
              International Insurance. Applications must be submitted through the registered agent, Anjouan Corporate 
              Services Ltd, and should be made on the prescribed application forms as follows  An application for the 
              consent of the Commissioner and the Authority to register as an insurer.<br/>
              Personal Questionnaire. Every Director, Controller, Manager and/or Promoter (Form PQ) of a person that 
              applies for a licence under the Act or who submits an application for consent to register.<br/>
              Financial Statements. Every application for a licence to carry on international insurance business in 
              Class II pursuant to the Act shall be accompanied by a Financial Statement which shall be made on Form 
              FS.<br/>
              Business Plan. Every application for a licence to carry on international insurance business pursuant to 
              the Act shall be accompanied by a Business Plan which shall be made on Form BP.<br/>
              Furthermore the application form shall be accompanied buy the necessary KYC and due diligence documents 
              as follows:  
              KYC and due diligence<br/>
              Please submit the following documents for each of the beneficial owner, director, manager, authorised 
              signatory, shareholder/member of the Entity or agent under power of attorney.  <br/>
              (a) For Individual<br/>
              A certified copy of passport or current driving licence or national identity card showing the name, 
              date and place of birth, nationality, signature of holder and data of issue/expiry. The document must 
              be certified by a banker, lawyer or actuary or notary or accountant holding a recognized professional 
              qualification.Proof of residential address. Acceptable evidence includes an original utility bill, 
              bank or credit card statement.<br/>
              Original Bank Reference.  <br/>
              (b) For Corporate/Partnership<br/>
              Certificate of incorporation or registration and the memorandum and articles of association or 
              constitution or deed of partnership or Act de Société as the case may be. Document has to be certified 
              by banker, lawyer or actuary or notary or accountant holding a recognized professional qualification.<br/>
              Original Bank Reference.<br/>
              A certified true copy of latest audited account or annual report.<br/>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InsuranceLicense;