import style from './Home.module.scss';
import Principles from '../../components/Principles/Principles';
import Services from '../../components/Services/Services';
import image from '../../assets/images/home1.jpg'

const Home = () => {
  return (
    <div className={style.content}>
      <div className={style.start_block}>
        <h1 className={style.start_block__header}>
          Anjouan Corporate Register
        </h1>
        <div className={style.start_block__text}>
          We are the representative of Anjouan Offshore Finance Authority
        </div>
      </div>
      <div className={style.info_block}>
        <div className={style.info_block__text}>
          Establishing a business venture in the free zone of Anjouan presents an attractive 
          option for foreign investors looking to tap into the vast potential and excellent 
          business environment offered by the Anjouan Offshore Finance Authority. Notably, 
          there are no FATF (Financial Action Task Force) or other regulatory controls, 
          making it an appealing jurisdiction for various business activities.
          
          <br/><br/>

          Our company is the sole certified agent cooperating closely with the Anjouan 
          Offshore Finance Authority. To avail yourself of any services offered by the 
          Anjouan Offshore Finance Authority, please contact us directly. Our dedicated 
          and professional team is committed to processing your applications promptly and 
          efficiently, ensuring swift communication and service delivery
        </div>
        <img src={image} 
             alt=""
             className={style.info_block__image} 
        />
      </div>
      <Services />
      <Principles />
    </div>
  );
}

export default Home;