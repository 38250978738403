import style from './BrokerageLicense.module.scss';
import brokerage1 from '../../../assets/images/brokerage1.jpg'

const BrokerageLicense = () => {

  const why = [
    {
      title: 'Regulatory Flexibility',
      text: 'Offshore jurisdictions often have streamlined regulatory frameworks, making it easier to obtain and maintain a brokerage or forex license compared to more stringent onshore jurisdictions. This flexibility allows for quicker setup and the ability to adapt swiftly to changing market conditions. Offshore regulatory bodies are typically more open to innovative financial products and services, fostering growth and innovation.'
    },
    {
      title: 'Tax Optimization',
      text: 'Offshore brokerage and forex firms may benefit from favorable tax regimes, allowing for greater efficiency in tax planning and optimization. These jurisdictions often offer lower tax rates or even tax exemptions on certain types of income, enabling your business to achieve significant cost savings. By strategically planning your tax obligations, you can enhance profitability and reinvest savings into expanding your services and client base.'
    },
    {
      title: 'Confidentiality',
      text: 'Many offshore jurisdictions prioritize client confidentiality, providing a secure environment for trading operations and client transactions. This high level of privacy helps protect client information from unauthorized access and potential threats. The commitment to confidentiality fosters trust and reliability, attracting clients who value discretion and security in their financial dealings.'
    },
    {
      title: 'Global Reaeh',
      text: 'By establishing an offshore brokerage or forex firm, you gain access to international markets and can cater to clients from around the world. This global reach enhances your business opportunities and revenue potential, positioning your firm as a key player in the international financial landscape. Offshore firms can offer services in multiple currencies and facilitate cross-border transactions with ease, expanding your customer base and service offerings.'
    },
  ]

  const formation = [
    'The percentage of ownership by the shareholders',
    'The details of the ultimate beneficial owners (chart may help if many)',
    'Any other useful information to support the application',
    'CVs of all the directors',
    'Full contact details of the directors and shareholders (KYC questionnaire)',
    'Bank reference for all the beneficial owners',
    'Clear certified passport copies of all beneficial owners',
    'Proof of addresses (utility bills)',
    'Business or legal reference for the beneficial owners',
    'Legal doc for the shareholding companies (articles, certificate of incorporation etc)',
    'Latest audited financial statements for all shareholding companies (if any)',
    'A certificate to prove that the beneficial owners do not have a criminal record'
  ]

  return (
    <div className={style.content}>
      <div className={style.start_block}>
        <h1 className={style.start_block__header}>
          Your Gateway To An Anjouan Comoros Offshore License
        </h1>
        <div className={style.start_block__text}>
            Brokerage License
        </div>
      </div>
      <div className={style.info_block}>
        <div className={style.main_block}>
          <div className={style.main_block__info}>
            <p>
              If you aim to establish a brokerage or forex business, we offer comprehensive support to navigate the licensing process with ease.
              Our expertise ensures a seamless transition from application to operation, focusing on regulatory compliance and operational
              efficiency. Our services encompass all aspects of brokerage and forex license acquisition, providing you with a strong foundation for
              success.
            </p>
            <h3>
              Why Obtain a Brokerage and Forex License in Anjouan?
            </h3>
            {why.map((item, e) => (
              <div className={style.main_block__item} key={e}>
                <div className={style.main_block__title}>{item.title}</div>
                <p>{item.text}</p>
              </div>
            ))}
          </div>
          <img src={brokerage1} alt="" />
        </div>
        <div className={style.formation_block}>
          <div className={style.formation_block__image}></div>
          <div className={style.formation_block__info}>
            <h3>Formation</h3>
            <p>
              Forming a brokerage is a relatively quick process due to the lack of bureaucracy. Approval can be granted swiftly 
              if the necessary requirements are met. There are no requirements for local offices or staff, although we would 
              suggest that all companies have this facility which can be arranged at a minimal cost (see Local office facility). 
              Application for permission for a Brokerage license should be made to Application Form License
            </p>
            <h3>
              The following documents will be required:
            </h3>
            <div className={style.formation_block__items}>
              {formation.map((item, e) => (
                <p key={e}>
                  {item}
                </p>
              ))}
            </div>
            <h3>
              KYC AND DUE DILIGENCE
            </h3>
            <p>
              A charge is made for each director / shareholder for due diligence (cost is dependent of country of residence ), 
              in the event that a client is not accepted for a license this payment is not refundable  <br/>
              Please submit the following documents for each of the beneficial owner, director, manager, authorised signatory, 
              shareholder/member of the Entity or agent under power of attorney.
            </p>
            <h3>
              (a) For Individual
            </h3>
            <p>
              A certified copy of passport or current driving license or national identity card showing the name, date and 
              place of birth, nationality, signature of holder and data of issue/expiry. The document must be certified by a 
              banker, lawyer or actuary or notary or accountant holding a recognized professional qualification.<br/>
              Proof of residential address. Acceptable evidence includes an original utility bill, bank or credit card 
              statement.<br/>
              Original Bank Reference.
            </p>
            <h3>
              (b) For Corporate/Partnership
            </h3>
            <p>
              Certificate of incorporation or registration and the memorandum and articles of association or constitution 
              or deed of partnership or Act
              de Société as the case may be. Document has to be certified by banker, lawyer or actual)· or notary or 
              accountant holding a recognized<br/>
              professional qualification.<br/>
              Original Bank Reference.<br/>
              A certified true copy of1atest audited account or annual report.<br/>
              A Certificate of good standing<br/>
              List of substantial shareholders (who holds not less than 5% of the voting power exercisable at the meeting 
              of shareholder/member) and a<br/>
              list of directors, and corporate structure.<br/>
              A certifi.ed copy of Board resolution granting authority to its managers, officers or employees to transact 
              business on its behalf
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BrokerageLicense;