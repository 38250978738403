import style from './Footer.module.scss';
import { Link } from 'react-router-dom';
import {
    ABOUT,
    GAMING_LICENSE
} from '../../Constants/RoutesConstants'

const Footer = () => {
    return (
        <footer className={style.content}>
            <div className={style.copyright}>
                Copyright © 2024 Anjouan Corporate Services. All Rights Reserved.
            </div>


            <div>
                Anjouan Corporate Register is the main agent of
                &nbsp;
                <a className={style.footerWebsiteLink} href='https://anjouanoffshorefinanceauthority.com/'>
                    anjouanoffshorefinanceauthority.com
                </a>
            </div>

            <div className={style.links}>
                <div className={`${style.links__link} ${style.links__company}`}>
                    <Link to={ABOUT}>
                        Company info
                    </Link>
                </div>
                <div className={`${style.links__link} ${style.links__gaming}`}>
                    <Link to={GAMING_LICENSE}>
                        Gaming Licenses
                    </Link>
                </div>


            </div>
        </footer>
    );
}

export default Footer;