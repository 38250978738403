import style from './GamingLicense.module.scss';
import gaming1 from '../../../assets/images/gaming1.jpg'
import gaming2 from '../../../assets/images/gaming2.jpg'

const GamingLicense = () => {
  return (
    <div className={style.content}>
      <div className={style.start_block}>
        <h1 className={style.start_block__header}>
          Your Gateway To An Anjouan Comoros Offshore License
        </h1>
        <div className={style.start_block__text}>
          Gaming License
        </div>
      </div>
      <div className={style.info_block}>
        <div className={style.main_block}>
          <p>
            Online Betting and gaming licenses are issued only to Anjouan companies, but
            allow a company to engage in as many different kinds of on line gambling
            promotions and under as many different brand names or web addresses as desired.
            Local nominee directors are acceptable if required.
            A fixed fee per year is charged. The Server may be located anywhere in the world, 
            and profits may be cleared through any bank. Betting and Gaming Law
            The Betting and Gaming Act 2005 was passed to legalise and regulate computer
            internet gaming, horse or greyhound betting and sports book betting, Casino,
            lottery and bingo on line gaming, to establish a Gaming Board, and to provide for
            related or incidental matters.
          </p>
          <img src={gaming1} alt="" />
        </div>
        <div className={style.formation_block}>
          <img src={gaming2} alt="" />
          <div className={style.formation_block__info}>
            <p>Formation</p>
            <p>
              The formation of an Online betting or gaming company has to be made on the application form as stipulated in 
              the act and the necessary KYC and due diligence documents as below to the Anjouan Corporate Services Ltd and 
              Gaming Board of Anjouan. The Gaming Board of Anjouan will then assess the application the Directors and 
              shareholders before grating the permission for the license.
            </p>
            <p>
              KYC AND DUE DILIGENCE
            </p>
            <p>
              Please submit the following documents for each of the beneficial owner, director, manager, authorised 
              signatory, shareholder/member of the Entity or agent under power of attorney.
            </p>
            <p>
              (a) For Individual
            </p>
            <p>
              A certified copy of passport or current driving licence or national identity card showing the name, date 
              and place of birth, nationality, signature of holder and data of issue/expiry. The document must be certified 
              by a banker, lawyer or actuary or notary or accountant holding a recognized professional qualification.<br/>
              Proof of residential address. Acceptable evidence includes an original utility bill, bank or credit card 
              statement.<br/>
              Original Bank Reference<br/>
              Certificate of Morality (Affidavit confirming that the individuals have no criminal track record).   
            </p>
            <p>
              (b) For Corporate/Partnership
            </p>
            <p>
              Certificate of incorporation or registration and the memorandum and articles of association or constitution 
              or deed of partnership or Act de Soci?t? as the case may be. Document has to be certified by banker, 
              lawyer or actuary or notary or accountant holding a recognized professional qualification. <br/>
              Original Bank Reference.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GamingLicense;