import { useState } from 'react';
import style from './MobileHeader.module.scss';
import { Link, useLocation } from 'react-router-dom';
import { 
  HOME,
  ABOUT,
  SERVICES,
  CONTACT,
  BANKING_LICENSE,
  BROKERAGE_LICENSE,
  INSURANCE_LICENSE,
  GAMING_LICENSE
 } from '../../Constants/RoutesConstants'
 
import logo from '../../assets/images/logo.png'
import phone from '../../assets/images/phone.svg'
import mail from '../../assets/images/envelope.svg'
import menu from '../../assets/images/menu.svg'
import close from '../../assets/images/close.svg'
import arrow from '../../assets/images/arrow.svg'

const Header = () => {

  const [open, setOpen] = useState(false);
  const [subNav, setSubNav] = useState(false);
  const location = useLocation()

  return (
    <header className={style.content}>
      <div className={style.info}>
        <div className={style.controls}>
          <Link to={HOME}>
            <img src={logo} alt="logo" className={style.logo} />
          </Link>
          <img src={menu} alt="" onClick={() => setOpen(true)} className={style.menu} />
        </div>
        <div className={style.contacts}>
          <div className={`${style.phone} ${style.contacts__icon}`}>
            <img src={phone} alt="phone" 
                 className={style.phone__icon}
            />
            <div className={style.phone__text}>
              <a href="tel:+2694582620">
                +2694582620
              </a>
            </div>
          </div>
          <div className={`${style.mail} ${style.contacts__icon}`}>
            <img src={mail} alt="mail" 
                 className={style.mail__icon}
            />
            <div className={style.mail__text}>
              <a href="mailto:management@anjouanregister.com">
                management@anjouanregister.com
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={open ? style.nav_active : style.nav}>
        <div className={style.nav__controls}>
          <Link to={HOME}>
            <img src={logo} alt="logo" className={style.nav__logo} />
          </Link>
          <img src={close} alt="close" className={style.nav__close} onClick={() => setOpen(false)} />
        </div>
        <ul className={style.nav__links}>
          <li className={location.pathname === '/' ? style.nav__link_active : style.nav__link}>
            <Link to={HOME}>
              Home
            </Link>
          </li>
          <li className={location.pathname.includes("about") ? style.nav__link_active : style.nav__link}>
            <Link to={ABOUT}>
              About
            </Link>
          </li>
          <li className={location.pathname.includes("services") ? style.nav__link_active : style.nav__link}>
            <Link to={SERVICES}>
              Services
            </Link>
          </li>
          <li className={`${location.pathname.includes("licenses") ? style.nav__link_active : style.nav__link} 
          ${style.nav__sub}`}>
            <div className={style.nav__sub_menu_control} onClick={() => setSubNav(!subNav)}>
              <div>Licenses</div>
              <img src={arrow} alt="arrow" style={{transform: subNav ? 'rotate(180deg)' : 'rotate(0deg)'}} />
            </div>
            <ul className={style.nav__sub_menu} style={{display: subNav ? 'flex' : 'none'}}>
              <li>
                <Link to={BANKING_LICENSE}>
                  Banking License
                </Link>
              </li>
              <li>
                <Link to={BROKERAGE_LICENSE}>
                  Brokerage License
                </Link>
              </li>
              <li>
                <Link to={GAMING_LICENSE}>
                  Gaming License
                </Link>
              </li>
              <li>
                <Link to={INSURANCE_LICENSE}>
                  Insurance License
                </Link>
              </li>
            </ul>
          </li>
          <li className={location.pathname.includes("contact") ? style.nav__link_active : style.nav__link}>
            <Link to={CONTACT}>
              Contact
            </Link>
          </li>
        </ul>
      </div>
    </header>
  );
}

export default Header;